import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";

export const TextPageTemplate = ({
  title,
  content,
  contentComponent,
  location,
}) => {
  const PageContent = contentComponent || Content;

  useEffect(() => {
    // TODO gatsby fixed it's anchor handling, consider removing it
    // gatsby doesn't handle anchors when they are used in Link, this covers it for How it Works page
    setTimeout(() => {
      if (typeof window !== "undefined" && location.hash !== "") {
        const anchors = [...document.querySelectorAll("h3")].filter(
          (elem) => elem.innerHTML === location.hash.substr(1),
        );
        if (anchors.length !== 0) {
          window.scrollTo(0, anchors[0].offsetTop);
        }
      }
    }, 100);
  }, [location.hash]);

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h2>
            <PageContent
              className="content has-text-justified"
              content={content}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

TextPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  location: PropTypes.object,
  contentComponent: PropTypes.func,
};

const TextPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TextPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        location={location}
      />
    </Layout>
  );
};

TextPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default TextPage;

export const textPageQuery = graphql`
  query TextPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
